import { mapActions, mapGetters, mapMutations } from 'vuex'
import General from '../general/index.vue'

export default {
  name: 'user-roles-dialog-view',
  props: {
    editedItem: {}
  },
  data () {
    return {
      dialog: true,
      payload: {
        name: '',
        title: ''
      }
    }
  },
  components: {
    General
  },
  watch: {
    openDialogView () {
      this.dialog = this.openDialogView
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'userRoles/currentItem',
      permissions: 'user/permissions',
      openDialogView: 'userRoles/openDialogView',
      userPermissions: 'userRoles/userPermissions',
      isListLoading: 'userRoles/isListLoading',
      isLoading: 'userRoles/isLoading'
    })
  },
  created () {
    if (this.editedItem) {
      this.fetchItem({ id: this.editedItem.id }).then(() => {
        this.payload.name = this.currentItem.name
        this.payload.title = this.currentItem.title
      })
    }
  },
  methods: {
    ...mapActions({
      fetchItem: 'userRoles/GET_LIST_ITEM',
      update: 'userRoles/UPDATE',
      create: 'userRoles/CREATE'
    }),
    ...mapMutations({
      changeDialogView: 'userRoles/CHANGE_DIALOG_VIEW',
      resetUserPermissions: 'userRoles/RESET_USER_PERMISSIONS',
      setItem: 'userRoles/SET_ITEM'
    }),
    changeCloseDialog () {
      this.dialog = false
      this.changeDialogView(false)
      this.resetUserPermissions()
    },
    save () {
      const formData = {
        name: this.payload.name,
        title: this.payload.title,
        permission_ids: []
      }
      Object.values(this.userPermissions).forEach(e => {
        formData.permission_ids.push(...e)
      })

      if (this.currentItem) {
        formData._method = 'patch'
        formData.id = this.currentItem.id
        this.update(formData).then(() => {
          this.changeCloseDialog()
          this.$toasted.success(this.$t('success_updated'))
        })
      } else {
        this.create(formData).then(() => {
          this.changeCloseDialog()
          this.$toasted.success(this.$t('success_created'))
        })
      }
    },
    updatedName (val) {
      this.payload.name = val
    },
    updatedTitle (val) {
      this.payload.title = val
    }
  },
  destroyed () {
    this.setItem(null)
  }
}
