import { mapActions, mapGetters, mapMutations } from 'vuex'
import DialogView from '../dialog-view/index.vue'

export default {
  name: 'user-roles-list',
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null,
      editedItem: null
    }
  },
  components: {
    DialogView
  },
  computed: {
    ...mapGetters({
      list: 'userRoles/list',
      limit: 'userRoles/limit',
      listLength: 'userRoles/listLength',
      isLoading: 'userRoles/isLoading',
      isListLoading: 'userRoles/isListLoading',
      openDialogView: 'userRoles/openDialogView'
    }),
    headers () {
      return [
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    }
    // listRules () {
    //   return this.list.map(rule => rule.permissions.data.length ? ({
    //     ...rule
    //   }) : ({
    //     createdAt: rule.createdAt,
    //     guardName: rule.guardName,
    //     id: rule.id,
    //     name: rule.name,
    //     title: rule.title,
    //     updatedAt: rule.updatedAt
    //   }))
    // }
  },
  created () {
    this.fetchList()
    this.fetchPermissions()
  },
  methods: {
    ...mapActions({
      fetchList: 'userRoles/GET_LIST',
      fetchPermissions: 'user/GET_PERMISSIONS',
      remove: 'userRoles/DELETE'
    }),
    ...mapMutations({
      changeSkip: 'userRoles/CHANGE_SKIP',
      changeDialogView: 'userRoles/CHANGE_DIALOG_VIEW',
      setList: 'userRoles/SET_LIST'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    changeDialog (status = false, item = null) {
      this.editedItem = item
      this.changeDialogView(status)
      if (!item) {
        this.editedItem = null
      }
    },
    removeLocal () {
      this.remove({ id: this.removingItem.id }).then(() => {
        this.showRemoveDialog = false
        this.removingItem = null
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDelete (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    }
  },
  destroyed () {
    this.setList([])
  }
}
