import { mapGetters } from 'vuex'
import validation from '@/mixins/validation'

import Permissions from '../../components/permission-service/index.vue'

export default {
  name: 'users-roles-general',
  mixins: [validation],
  data () {
    return {
      tab: '',
      payload: {
        name: '',
        title: '',
        permissionIds: []
      }
    }
  },
  watch: {
    currentItem () {
      this.setName()
    }
  },
  mounted () {
    this.setName()
  },
  created () {

  },
  components: {
    Permissions
  },
  computed: {
    ...mapGetters({
      currentItem: 'userRoles/currentItem',
      permissions: 'user/permissions',
      openDialogView: 'userRoles/openDialogView',
    })
  },
  methods: {
    setName () {
      if (this.currentItem) {
        setTimeout(() => {
          this.payload.title = this.currentItem.title
        }, 200)
      }
    },
    updateName () {
      this.$emit('update-name', this.payload.name)
    },
    updateTitle () {
      this.$emit('update-title', this.payload.title)
    }
  }
}
