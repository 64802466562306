import PermissionServiceItem from '../permission-service-item'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'permission-service-item',
  props: {
    item: Object,
    microservice: {
      type: String
    },
    name: {
      type: String,
      default: ''
    },
    subIndex: {
      default: 1
    }
  },
  data () {
    return {
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters({
      userPermissions: 'userRoles/userPermissions',
      permissions: 'user/permissions'
    })
  },
  components: {
    PermissionServiceItem
  },
  methods: {
    ...mapMutations({
      changeUserPermissions: 'userRoles/CHANGE_USER_PERMISSIONS'
    }),
    changePermissionItem (val, item) {
      this.changeUserPermissions({
        isAddItem: !!val,
        microservice: this.microservice,
        id: item.id
      })
      this.$emit('update-checkbox', val)
    },
    getInputValue (id) {
      return this.userPermissions.hasOwnProperty(this.microservice) && this.userPermissions[this.microservice].find(e => e === id)
    }
  }
}
